const projectData = [
    {
        projectId: 1,
        projectTitle: 'SpudBud',
        demoLink: 'https://www.youtube.com/embed/0c3RmzH738U',
        gitHubLink: 'https://github.com/Jswens24/SpudBud',
        hosted: false,
        projectDescription: "SpudBud is a children's adventure game involving a traveling potato. It is designed to help children learn context clues by matching appropriate items to a specific location. You can enter your name to create a user account in my database and then from there you will be provided a random location and a randomized list of items. You need to correctly match what the potato needs to pack for that location. For example, if the potato is going to school it might need a backpack, notebook, and pencil. If the correct items are selected your Spudbud will teleport to that location with the correct items. You can also save all the places you have been to with your specific username.",
        technologiesUsed: 'JavaScript | HTML | CSS | Node.js | Express | Axios | Sequelize | Heroku'
    },
    {
        projectId: 2,
        projectTitle: 'Campanion',
        demoLink: 'https://www.youtube.com/embed/h1ME3sJ-UcM',
        gitHubLink: 'https://github.com/Jswens24/campanion',
        hosted: false,
        projectDescription: "Campanion is a camping journal I created to keep information about different off-grid campsites I like to visit.  Users can log in to see their campsites as well as create new campsites by entering basic information like a title, picture, and coordinates.  When searching or filtering through your campsites, you can click on them and see a more detailed view.  I connected to weatherbit.io's API to show you the current weather for that location.  I also connected to google maps API to show your location on a map as well as provide directions.",
        technologiesUsed: 'JavaScript | HTML | CSS | React | Node.js | Express | Axios | Sequelize  | Bit.io | Google Maps Api | Weatherbit.io'
    },
    {
        projectId: 3,
        projectTitle: 'Jessica News',
        demoLink: 'https://www.youtube.com/embed/mNCoxEBdTjg',
        gitHubLink: 'https://github.com/Jswens24/news-app',
        hosted: false,
        projectDescription: 'Jessica News is a styling project that I built using tailwindcss.  The design is based on a popular news source and uses React for the front end.',
        technologiesUsed: 'JavaScript | HTML | CSS | React | Axios | tailwindcss | newsapi.org'
    },
    {
        projectId: 4,
        projectTitle: 'Wildhare',
        demoLink: 'https://www.youtube.com/embed/xK7HZ96hXtk',
        gitHubLink: 'https://github.com/Jswens24/wildhare',
        hosted: true,
        projectDescription: 'Wildhare is an e-commerce website that seamlessly integrates with an existing eBay store. Leveraging the power of React, I crafted a modern and responsive online shopping platform that ensures an optimal user experience across all devices. This project showcases my expertise in web development and my ability to extend and enhance existing e-commerce solutions, making it easier for businesses to expand their online presence and serve customers effectively.',
        technologiesUsed: 'JavaScript | HTML | CSS | React',
        hostedLink: 'https://main--ornate-bienenstitch-3cef9b.netlify.app'
    },
    {
        projectId: 5,
        projectTitle: 'Flash Cards',
        demoLink: 'https://youtube.com/embed/EGWAPPJzCB4?si=tP1ERUilBWW_uJsh',
        gitHubLink: 'https://github.com/Jswens24/flash-cards',
        hosted: true,
        projectDescription: 'Showcasing my React skills with a simple yet effective Flash Card App. Users can effortlessly study a variety of topics with an easy-to-use list. ',
        technologiesUsed: 'JavaScript | HTML | CSS | React | Tailwindcss',
        hostedLink: 'https://main--rainbow-gnome-bd662b.netlify.app/'
    },
    {
        projectId: 6,
        projectTitle: 'Swenco',
        demoLink: 'https://www.youtube.com/embed/Ticsdh-mgpM?si=UgkLHfTzxH_iBofp',
        gitHubLink: 'https://github.com/Jswens24/Swenco',
        hosted: true,
        projectDescription: 'A testament to my proficiency in HTML, JavaScript, React, and Tailwind CSS. This sleek and responsive site seamlessly integrates interactive elements, demonstrating a perfect blend of technology and design for a leading construction company. Explore the future of online presence in construction with this dynamic showcase.',
        technologiesUsed: 'JavaScript | HTML | CSS | React | Tailwindcss',
        hostedLink: 'https://swenco.construction/'


    }
]





module.exports = { projectData }